import HeroBackground from "./HeroBackground";
import React from "react";
import { useNavigate } from "react-router-dom";

const Hero = ({ judul }) => {
  const navigateTo = useNavigate();
  const currentPathname = window.location.pathname;
  const handleNavigate = (e, path) => {
    e.preventDefault();
    if (path !== currentPathname) {
      navigateTo(path);
    }
  };
  return (
    <>
      <div className="row mx-0">
        <div className="col-12 header-title">
          <h1 className="fw-bold text-white">{judul}</h1>
          <nav className="bg-transparent">
            <ol className="breadcrumb justify-content-center bg-transparent">
              <li className="breadcrumb-item">
                <a
                  href="/"
                  onClick={(e) => handleNavigate(e, "/")}
                  className="fw-thin text-white text-decoration-none"
                >
                  Beranda
                </a>
              </li>
              <li className="breadcrumb-item fw-bolder text-white active">
                {judul}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="admin-hero">
        <HeroBackground />
      </div>
    </>
  );
};

export default Hero;
