import DataTable from "react-data-table-component";
import { BsPencilSquare } from "react-icons/bs";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { TableKontak, useKontakContext } from "../../context/TableKontak";
import React from "react";

const Table = () => {
  const { reloadTable, data, get, bukaEdit } = useKontakContext();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    get();
    setLoad(false);
  }, [reloadTable]);

  const tbl = data.map((item, index) => ({
    no: index + 1,
    id: item.id,
    judul: item.judul,
    isi: item.isi,
  }));

  const columns = [
    {
      name: "No",
      width: "4rem",
      center: true,
      selector: (row) => row.no,
    },
    {
      name: "Judul",
      selector: (row) => row.judul,
    },
    {
      name: "Link",
      selector: (row) => row.isi,
    },
    {
      name: "Aksi",
      button: true,
      center: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-main p-1 px-2 me-1"
            onClick={() => bukaEdit(row.judul, row.isi, row.id)}
          >
            <BsPencilSquare />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={tbl}
        progressPending={load}
        pagination={true}
        striped
        title="Kontak"
      />
    </>
  );
};

const ModalKontak = () => {
  const { update, errors, judul, isi, setIsi, isDisabled, tutupEdit, modal } =
    useKontakContext();
  return (
    <>
      {/* MODAL */}
      <Modal show={modal} onHide={tutupEdit} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Edit {judul}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="judul" className="form-label fw-semibold">
                Judul
              </label>
              <input
                type="text"
                id="judul"
                className="form-control"
                value={judul}
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="isi" className="form-label fw-semibold">
                Link
              </label>
              <textarea
                id="isi"
                className={
                  errors.isi ? "form-control is-invalid" : "form-control"
                }
                rows="4"
                placeholder="Masukkan Isi Disini . . ."
                onChange={(e) => setIsi(e.target.value)}
              >
                {isi}
              </textarea>
              {errors.isi && (
                <label className="text-danger mt-1">{errors.isi}</label>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary mb-3" onClick={tutupEdit}>
            Tutup
          </button>
          <button
            className="btn btn-success mb-3"
            disabled={isDisabled}
            onClick={update}
          >
            Simpan
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Kontak = () => {
  return (
    <>
      <TableKontak>
        <ModalKontak />
        <Table />
      </TableKontak>
    </>
  );
};

export default Kontak;
