import axios from "../axiosConfig";
import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";

const Beranda = createContext();
export const BerandaContext = ({ children }) => {
  const [kontak, setKontak] = useState({});

  const get = async () => {
    try {
      const response = await axios.get("/api/kontak");
      const data = response.data;
      let tmp = {};
      data.map((item) => {
        tmp[item.judul] = item.isi;
      });
      setKontak(tmp);
    } catch (error) {
      console.log("tejadi masalah :", error);
    }
  };
  useEffect(() => {
    get();
  }, []);

  return <Beranda.Provider value={{ kontak }}>{children}</Beranda.Provider>;
};

export const useBerandaContext = () => {
  return useContext(Beranda);
};
