import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useAuth } from "../context/AuthContext";
import "../assets/css/main.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React from "react";
import { useNavigate } from "react-router-dom";
const NavbarAdmin = () => {
  const { logout } = useAuth();
  const Alert = withReactContent(Swal);
  const navigateTo = useNavigate();
  const currentPathname = window.location.pathname;

  const handleNavigate = (path) => {
    if (path !== currentPathname) {
      navigateTo(path);
    }
  };

  const handleLogout = () => {
    Alert.fire({
      title: <p>Yakin Ingin Logout ?</p>,
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        return logout();
      }
    });
  };
  return (
    <Navbar expand="lg" className="py-2 sticky-top bg-white shadow-sm">
      <Container>
        <Navbar.Brand
          className="cursor-pointer"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <span className="fw-semibold">Admin</span>
        </Navbar.Brand>
        <Navbar.Toggle className="navbar-toggle" aria-controls="navbar" />
        <Navbar.Collapse id="navbar" className="justify-content-end">
          <Nav className="ms-auto gap-3">
            <Nav.Link
              onClick={() => handleNavigate("/admin/pricelist")}
              className={currentPathname === "/admin/pricelist" ? "active" : ""}
            >
              Pricelist
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigate("/admin/portofolio")}
              className={
                currentPathname === "/admin/portofolio" ? "active" : ""
              }
            >
              Portofolio
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigate("/admin/faq")}
              className={currentPathname === "/admin/faq" ? "active" : ""}
            >
              FAQ
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigate("/admin/daily")}
              className={currentPathname === "/admin/daily" ? "active" : ""}
            >
              Daily
            </Nav.Link>
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarAdmin;
