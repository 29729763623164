import Accordion from "react-bootstrap/Accordion";
import { useEffect, useState } from "react";
import axios from "../axiosConfig";
import React from "react";

const Faq = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/faq");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="container mt-section">
      <div className="text-center">
        <h2 className="fw-semibold">Yang Sering Ditanyakan</h2>
        <p>
          Terdapat beberapa hal yang yang sering ditanyakan kepada kami, <br /> anda dapat menanyakan hal berbeda melalui kontak
          kami. Mari hubungi kami
        </p>
      </div>

      <div className="mt-4">
        {data.map((item) => (
          <Accordion className="mt-2" key={item.id}>
            <Accordion.Item eventKey={item.id}>
              <Accordion.Header>{item.judul}</Accordion.Header>
              <Accordion.Body className="bg-faq rounded-bottom ">
                <div dangerouslySetInnerHTML={{ __html: item.isi }} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default Faq;
