import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LoginBackground from "../components/LoginBackground";
import Loader from "../components/Loader";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "../axiosConfig";
import * as Yup from "yup";

function FormLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gagal, setGagal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { login, isAuthenticated } = useAuth();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigateTo("/admin/pricelist");
  }, [isAuthenticated, navigateTo]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const schema = Yup.object().shape({
    email: Yup.string().email("Email tidak valid").required("Email wajib diisi"),
    password: Yup.string().required("Password wajib diisi"),
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    const Alert = withReactContent(Swal);
    try {
      await schema.validate({ email, password }, { abortEarly: false });
      const validationErrors = {};
      setIsDisabled(true);
      setErrors(validationErrors);
      axios
        .post("/api/login", data)
        .then((response) => {
          const { token } = response.data;
          login(token);
          navigateTo("/admin/pricelist");
        })
        .then(() => {
          Alert.fire({
            title: <p>Login Berhasil !</p>,
            icon: "success",
          });
        })
        .catch(() => {
          setGagal(true);
          setIsDisabled(false);
          Alert.fire({
            title: <p>Login Gagal !</p>,
            icon: "error",
          });
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setGagal(false);
      setErrors(validationErrors);
    }
  };
  return (
    <div className="row justify-content-center">
      <form onSubmit={handleLogin} className="col-md-6 login-area p-5">
        <h3 className="text-center mb-4">Login</h3>
        <div className="mb-3">
          <label htmlFor="email" className="form-label fw-semibold">
            Email
          </label>
          <input
            type="text"
            id="email"
            className={errors.email || gagal ? "form-control py-2 is-invalid" : "form-control py-2"}
            placeholder="Masukkan Email Disini . . ."
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <label className="text-danger mt-1">{errors.email}</label>}
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label fw-semibold">
            Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            defaultValue={password}
            className={errors.password || gagal ? "form-control py-2 is-invalid" : "form-control py-2"}
            placeholder="Masukkan Password Disini . . ."
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="password-icon" onClick={toggleShowPassword}>
            {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
          </span>
          {errors.password && <label className="text-danger mt-1">{errors.password}</label>}
        </div>
        {gagal && <label className="text-danger mb-2">Email atau Password Salah !</label>}
        <button
          className="btn btn-main w-100 py-2 mt-2 d-flex justify-content-center align-items-center fw-semibold"
          disabled={isDisabled}
        >
          {isDisabled ? <Loader /> : "Login"}
        </button>
      </form>
    </div>
  );
}

const Login = () => {
  return (
    <>
      <LoginBackground />
      <FormLogin />
    </>
  );
};
export default Login;
