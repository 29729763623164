import React from "react";
function KeunggulanCard(props) {
  return (
    <div className="row col-lg-4 keunggulan">
      <div className="col-3">
        <img src={props.src} alt="" className="w-100" />
      </div>
      <div className="col-12 text-center mt-3">
        <h5>{props.title}</h5>
        <p className="fs-normal">{props.children}</p>
      </div>
    </div>
  );
}

export default KeunggulanCard;
