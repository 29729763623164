import DataTable from "react-data-table-component";
import { BsPencilSquare, BsTrash3Fill, BsEyeFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { TablePricelist, usePricelistContext } from "../../context/TablePricelist";
import React from "react";

const Table = () => {
  const { reloadTable, data, get, del, bukaEdit } = usePricelistContext();
  const [load, setLoad] = useState(true);

  function rupiah(angka) {
    angka = parseInt(angka);
    const uang = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(angka);

    return uang;
  }
  const handleOpenNewTab = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    get();
    setLoad(false);
  }, [reloadTable]);

  const tbl = data.map((item, index) => ({
    no: index + 1,
    id: item.id,
    judul: item.judul,
    harga: item.harga,
    link_demo: item.link_demo,
    diskon: item.diskon,
    foto: item.foto,
  }));

  const columns = [
    {
      name: "No",
      width: "4rem",
      center: true,
      selector: (row) => row.no,
    },
    {
      name: "Gambar",
      center: true,
      selector: (row) => <img src={row.foto} className="w-100 my-2" loading="lazy" style={{ maxWidth: "200px" }} alt="" />,
    },
    {
      name: "Judul",
      wrap: true,
      selector: (row) => row.judul,
    },
    {
      name: "Harga",
      wrap: true,
      selector: (row) =>
        row.diskon === 0 ? (
          <span>{rupiah(row.harga)}</span>
        ) : (
          <>
            <span className="text-decoration-line-through">{rupiah(row.harga)}</span>
            <br />
            <span>
              {rupiah(row.harga - (row.harga * parseInt(row.diskon)) / 100)} (diskon {row.diskon}% )
            </span>
          </>
        ),
    },
    {
      name: "Aksi",
      button: true,
      center: true,
      width: "10rem",
      cell: (row) => (
        <>
          <button className="btn btn-info p-1 px-2 me-1" onClick={() => handleOpenNewTab(row.link_demo)}>
            <BsEyeFill />
          </button>
          <button
            className="btn btn-main p-1 px-2 me-1"
            onClick={() => bukaEdit(row.judul, row.foto, row.harga, row.diskon, row.link_demo, row.id)}
          >
            <BsPencilSquare />
          </button>
          <button className="btn btn-danger p-1 px-2" onClick={() => del(row.id)}>
            <BsTrash3Fill />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable columns={columns} data={tbl} progressPending={load} pagination={true} striped />
    </>
  );
};

const ModalPricelist = () => {
  const {
    setJudul,
    setDemo,
    setDiskon,
    setHarga,
    handleFoto,
    judul,
    diskon,
    harga,
    demo,
    tipe,
    post,
    update,
    preview,
    errors,
    isDisabled,
    tutupTambah,
    tutupEdit,
    modal,
    bukaTambah,
  } = usePricelistContext();
  return (
    <>
      <button className="btn btn-main my-3" onClick={bukaTambah}>
        +Tambah
      </button>

      {/* MODAL */}
      <Modal show={modal} onHide={tipe === "Tambah" ? tutupTambah : tutupEdit} scrollable size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{tipe} Pricelist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="judul" className="form-label fw-semibold">
                Judul
              </label>
              <input
                type="text"
                id="judul"
                className={errors.judul ? "form-control is-invalid" : "form-control"}
                placeholder="Masukkan Judul Disini . . ."
                value={judul}
                onChange={(e) => setJudul(e.target.value)}
              />
              {errors.judul && <label className="text-danger mt-1">{errors.judul}</label>}
            </div>
            <div className="mb-3">
              <label htmlFor="harga" className="form-label fw-semibold">
                Harga
              </label>
              <input
                type="number"
                id="harga"
                className={errors.harga ? "form-control is-invalid" : "form-control"}
                placeholder="Masukkan Harga Disini . . ."
                value={harga}
                onChange={(e) => setHarga(e.target.value)}
              />
              {errors.harga && <label className="text-danger mt-1">{errors.harga}</label>}
            </div>
            <div className="mb-3">
              <label htmlFor="diskon" className="form-label fw-semibold">
                Diskon (Dalam persentase %)
              </label>
              <input
                type="number"
                id="diskon"
                className={errors.diskon ? "form-control is-invalid" : "form-control"}
                placeholder="Masukkan Diskon Disini . . ."
                value={diskon}
                onChange={(e) => setDiskon(e.target.value)}
              />
              {errors.diskon && <label className="text-danger mt-1">{errors.diskon}</label>}
            </div>
            <div className="mb-3">
              <label htmlFor="demo" className="form-label fw-semibold">
                Link Demo
              </label>
              <textarea
                id="demo"
                className={errors.demo ? "form-control is-invalid" : "form-control"}
                rows="4"
                placeholder="Masukkan Link Demo Disini . . ."
                onChange={(e) => setDemo(e.target.value)}
              >
                {demo}
              </textarea>
              {errors.demo && <label className="text-danger mt-1">{errors.demo}</label>}
            </div>
            <div className="mb-3">
              <label htmlFor="foto" className="form-label fw-semibold">
                Foto
              </label>
              <input
                type="file"
                id="foto"
                className={errors.foto ? "form-control is-invalid" : "form-control"}
                accept="image/*"
                onChange={handleFoto}
              />
              {preview ? (
                <div className="text-center">
                  <img src={preview} alt="" className="w-50 mt-3" />
                </div>
              ) : null}
              {errors.foto && <label className="text-danger mt-1">{errors.foto}</label>}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {tipe === "Tambah" ? (
            <>
              <button className="btn btn-secondary mb-3" onClick={tutupTambah}>
                Tutup
              </button>
              <button className="btn btn-success mb-3" disabled={isDisabled} onClick={(e) => post(e)}>
                Simpan
              </button>
            </>
          ) : (
            <>
              <button className="btn btn-secondary mb-3" onClick={tutupEdit}>
                Tutup
              </button>
              <button className="btn btn-success mb-3" disabled={isDisabled} onClick={update}>
                Simpan
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const PricelistCRUD = () => {
  return (
    <TablePricelist>
      <ModalPricelist />
      <Table />
    </TablePricelist>
  );
};

export default PricelistCRUD;
