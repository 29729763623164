import { createContext, useContext, useState } from "react";
import { useAuth } from "./AuthContext";
import axios from "../axiosConfig";
import Swal from "sweetalert2";
import * as Yup from "yup";
import React from "react";
import withReactContent from "sweetalert2-react-content";

const PortofolioContext = createContext();

export const usePortofolioContext = () => {
  return useContext(PortofolioContext);
};

export const TablePortofolio = ({ children }) => {
  const [reloadTable, setReloadTable] = useState(false);
  const [data, setData] = useState([]);
  const [judul, setJudul] = useState("");
  const [foto, setFoto] = useState();
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const [tipe, setTipe] = useState("");
  const tutupTambah = () => {
    setTipe("");
    setModal(false);
  };
  const bukaTambah = () => {
    setTipe("Tambah");
    setModal(true);
  };
  const tutupEdit = () => {
    setTipe("");
    setJudul("");
    setFoto(null);
    setPreview(null);
    setId(null);
    setModal(false);
  };
  const bukaEdit = (judul, foto, id) => {
    setTipe("Edit");
    setJudul(judul);
    setFoto(foto);
    setPreview(foto);
    setId(id);
    setModal(true);
  };

  const handleFoto = (e) => {
    const selectedFile = e.target.files[0];
    const img = URL.createObjectURL(selectedFile);
    setFoto(selectedFile);
    setPreview(img);
  };

  const { userToken, logout } = useAuth();
  const Alert = withReactContent(Swal);

  // VALIDASI
  const MAX_FILE_SIZE = 2048000;
  const validFileExtensions = {
    image: ["jpg", "png", "jpeg"],
  };
  function isValidFileType(fileName, fileType) {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1;
  }
  const validasiTambah = Yup.object().shape({
    judul: Yup.string().required("Judul wajib diisi"),
    foto: Yup.mixed()
      .required("Foto Harus Diisi")
      .test("", "Foto Tidak Valid", (value) => isValidFileType(value && value.name.toLowerCase(), "image"))
      .test("", "File terlalu besar", (value) => value && value.size <= MAX_FILE_SIZE),
  });
  const validasiEdit = Yup.object().shape({
    judul: Yup.string().required("Judul wajib diisi"),
  });

  // GET DATA
  const get = async () => {
    try {
      const response = await axios.get("/api/admin/portofolio", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      setData(response.data);
    } catch (error) {
      if (error.response?.status === 401) logout();
      console.error("Error fetching data:", error);
    }
  };

  // DELETE DATA
  const del = (id) => {
    Alert.fire({
      title: <p>Yakin Ingin Menghapus Data ?</p>,
      icon: "warning",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/admin/portofolio/delete/${id}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          });
          Alert.fire({
            title: <p>Berhasil Menghapus Data</p>,
            icon: "success",
          });
          handleReloadTable();
        } catch (error) {
          if (error.response?.status === 401) logout();
          Alert.fire({
            title: <p>Gagal Menghapus Data</p>,
            icon: "error",
          });
          console.error("Error fetching data:", error);
        }
      }
    });
  };

  // POST DATA
  const post = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("judul", judul);
    formData.append("foto", foto);

    try {
      await validasiTambah.validate({ judul, foto }, { abortEarly: false });
      setIsDisabled(true);
      setErrors({});
      await axios
        .post("/api/admin/portofolio/add", formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(() => {
          resetForm();
          Alert.fire({
            title: <p>Tambah Data Berhasil !</p>,
            icon: "success",
          });
          handleReloadTable();
        });
    } catch (error) {
      if (error.response?.status === 401) logout();
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsDisabled(false);
      setErrors(validationErrors);
    }
  };

  // UPDATE DATA
  const update = async (e) => {
    e.preventDefault();
    const formEdit = new FormData();
    formEdit.append("judul", judul);
    formEdit.append("foto", foto);

    try {
      await validasiEdit.validate({ judul }, { abortEarly: false });
      setIsDisabled(true);
      setErrors({});
      await axios
        .post(`/api/admin/portofolio/update/${id}`, formEdit, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(() => {
          resetForm();
          Alert.fire({
            title: <p>Update Data Berhasil !</p>,
            icon: "success",
          });
          handleReloadTable();
        });
    } catch (error) {
      if (error.response?.status === 401) logout();
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsDisabled(false);
      setErrors(validationErrors);
    }
  };

  // RESET FORM
  const resetForm = () => {
    tipe === "Tambah" ? tutupTambah() : tutupEdit();
    setJudul("");
    setFoto(null);
    setErrors({});
    setIsDisabled(false);
  };

  const handleReloadTable = () => {
    setReloadTable((prevValue) => !prevValue);
  };

  return (
    <PortofolioContext.Provider
      value={{
        reloadTable,
        data,
        userToken,
        errors,
        isDisabled,
        judul,
        foto,
        tipe,
        modal,
        preview,
        handleReloadTable,
        get,
        del,
        post,
        update,
        setJudul,
        handleFoto,
        tutupTambah,
        bukaTambah,
        tutupEdit,
        bukaEdit,
      }}
    >
      {children}
    </PortofolioContext.Provider>
  );
};
