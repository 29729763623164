import React from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";
import Beranda from "./pages/Beranda";
import Login from "./pages/Login";
import Pricelist from "./pages/admin/Pricelist";
import Portofolio from "./pages/admin/Portofolio";
import { useAuth } from "./context/AuthContext";
import axios from "./axiosConfig";
import Daily from "./pages/admin/Daily";
import FAQ from "./pages/admin/FAQ";
import { useEffect } from "react";

function NoPage() {
  const navigateTo = useNavigate();
  useEffect(() => {
    navigateTo("/");
  }, [navigateTo]);
}

function App() {
  const { userToken } = useAuth();
  axios.interceptors.request.use(
    (config) => {
      if (userToken) {
        config.headers.Authorization = `Bearer ${userToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Beranda />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin/pricelist" element={<PrivateRoute />}>
          <Route path="/admin/pricelist" element={<Pricelist />} />
        </Route>
        <Route path="/admin/portofolio" element={<PrivateRoute />}>
          <Route path="/admin/portofolio" element={<Portofolio />} />
        </Route>
        <Route path="/admin/daily" element={<PrivateRoute />}>
          <Route path="/admin/daily" element={<Daily />} />
        </Route>
        <Route path="/admin/faq" element={<PrivateRoute />}>
          <Route path="/admin/faq" element={<FAQ />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}
const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default App;
