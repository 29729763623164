import Container from "react-bootstrap/Container";
import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../assets/images/logo.png";
import { BsWhatsapp } from "react-icons/bs";
import { useBerandaContext } from "../context/BerandaContext";

const NavbarMain = () => {
  const { kontak } = useBerandaContext();
  const [cgBg, setCgBg] = useState(false);
  const [top, setTop] = useState(false);
  const [active, setActive] = useState("");
  const handleOpenNewTab = (link) => {
    window.open(link, "_blank");
  };
  useEffect(() => {
    const handleScroll = () => {
      const layanan = document.getElementById("layanan");
      const tentang = document.getElementById("tentang");
      const portofolio = document.getElementById("portofolio");
      const pricelist = document.getElementById("pricelist");
      const offsetLayanan =
        layanan.getBoundingClientRect().top + window.scrollY - 200;
      const offsetTentang =
        tentang.getBoundingClientRect().top + window.scrollY - 200;
      const offsetPortofolio =
        portofolio.getBoundingClientRect().top + window.scrollY - 200;
      const offsetPricelist =
        pricelist.getBoundingClientRect().top + window.scrollY - 200;
      const scrollY = window.scrollY;
      if (scrollY >= 100 || window.innerWidth <= 991) {
        setCgBg(true);
      } else setCgBg(false);
      if (scrollY < offsetLayanan) {
        setActive("Beranda");
        setTop(false);
      } else if (scrollY >= offsetLayanan && scrollY < offsetTentang) {
        setActive("Layanan");
        setTop(true);
        setTop(false);
      } else if (scrollY >= offsetTentang && scrollY < offsetPortofolio) {
        setActive("Tentang");
        setTop(true);
      } else if (scrollY >= offsetPortofolio && scrollY < offsetPricelist) {
        setActive("Portofolio");
        setTop(true);
      } else if (scrollY >= offsetPricelist) {
        setActive("Pricelist");
        setTop(true);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scroll = (val) => {
    if (val === 0) {
      window.scrollTo(0, val);
    } else {
      const element = document.getElementById(val);
      if (element) {
        const offset =
          element.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <Navbar
      expand="lg"
      className={`py-2 fixed-top ${cgBg ? "bg-white shadow-sm" : ""}`}
    >
      <Container>
        <Navbar.Brand className="cursor-pointer" onClick={() => scroll(0)}>
          <div className="row align-items-center">
            <img
              alt=""
              src={logo}
              width="40"
              height="40"
              className="col-auto d-inline-block align-top mx-2 p-0"
            />
            <span className="col fw-semibold black p-0">TigeWeb</span>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle className="navbar-toggle" aria-controls="navbar" />
        <Navbar.Collapse id="navbar" className="justify-content-end">
          <Nav className="ms-auto gap-3">
            <Nav.Link
              className={active === "Beranda" ? "active" : ""}
              onClick={() => scroll(0)}
            >
              Beranda
            </Nav.Link>
            <Nav.Link
              className={active === "Layanan" ? "active" : ""}
              onClick={() => scroll("layanan")}
            >
              Layanan
            </Nav.Link>
            <Nav.Link
              className={active === "Tentang" ? "active" : ""}
              onClick={() => scroll("tentang")}
            >
              Tentang
            </Nav.Link>
            <Nav.Link
              className={active === "Portofolio" ? "active" : ""}
              onClick={() => scroll("portofolio")}
            >
              Portofolio
            </Nav.Link>
            <Nav.Link
              className={active === "Pricelist" ? "active" : ""}
              onClick={() => scroll("pricelist")}
            >
              Pricelist
            </Nav.Link>
            <Nav.Link
              className="btn btn-primary rounded-pill w-max"
              onClick={() => handleOpenNewTab(kontak["Whatsapp"])}
            >
              <span className="d-flex align-items-center text-white">
                <BsWhatsapp className="me-1" /> Kontak Kami
              </span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <div
        className={`back-to-top ${!top ? "d-none" : ""}`}
        onClick={() => scroll(0)}
      ></div>
    </Navbar>
  );
};

export default NavbarMain;
