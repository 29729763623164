import KeunggulanCard from "./KeunggulanCard";
import keunggulan_1 from "../assets/images/icon-keunggulan-1.png";
import keunggulan_2 from "../assets/images/layanan-interaksi.png";
import keunggulan_3 from "../assets/images/layanan-informasi.png";
import keunggulan_4 from "../assets/images/layanan-pemasaran.png";
import keunggulan_5 from "../assets/images/layanan-penglacakan.png";
import keunggulan_6 from "../assets/images/layanan-visibilitas.png";
import React from "react";

function Keunggulan() {
  return (
    <div className="container mt-section">
      <div className="text-center">
        <h2 className="fw-semibold">6 Keunggulan Memiliki Website</h2>
        <p>
          Terdapat beberapa keunggulan yang anda dapatkan jika anda <br />{" "}
          memiliki webiste untuk usaha dan bisnis anda.
        </p>
      </div>
      <div className="row justify-content-center gap-sub">
        <KeunggulanCard title="Keterjangkauan & Keterbukaan" src={keunggulan_1}>
          Website memberikan kemampuan untuk menjangkau audiens yang lebih luas
          secara global, karna dapat di akses secara online.
        </KeunggulanCard>
        <KeunggulanCard title="Meningkatkan Visibilitas" src={keunggulan_6}>
          Website dapat membantu meningkatkan visibilitas bisnis atau organisasi
          di platform secara online dengan cepat dan mudah.
        </KeunggulanCard>
        <KeunggulanCard title="Menyajikan Informasi Lengkap" src={keunggulan_3}>
          Website dapat menyajikan informasi secara lengkap tentang bisnis atau
          organisasi anda, termasuk deskripsi produk dan lain-lain.
        </KeunggulanCard>
        <KeunggulanCard title="Memperluas Pemasaran" src={keunggulan_4}>
          Melalui website, bisnis atau organisasi dapat melakukan pemasaran
          secara online, seperti berbagi konten menarik dan bernilai jual
          tinggi.
        </KeunggulanCard>
        <KeunggulanCard title="Interaksi dengan Pelanggan" src={keunggulan_2}>
          Website dapat menjadi saluran interaksi antara bisnis atau organisasi
          dengan pelanggan dengan mudah.
        </KeunggulanCard>
        <KeunggulanCard title="Pelacakan & Analisis" src={keunggulan_5}>
          Dengan menggunakan alat analisis web, seperti Google Analytics, bisnis
          atau organisasi dapat melacak kinerja website mereka.
        </KeunggulanCard>
      </div>
    </div>
  );
}

export default Keunggulan;
