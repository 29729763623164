import { createContext, useContext, useState } from "react";
import { useAuth } from "./AuthContext";
import axios from "../axiosConfig";
import Swal from "sweetalert2";
import * as Yup from "yup";
import withReactContent from "sweetalert2-react-content";
import React from "react";

const KontakContext = createContext();

export const useKontakContext = () => {
  return useContext(KontakContext);
};

export const TableKontak = ({ children }) => {
  const [reloadTable, setReloadTable] = useState(false);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const [judul, setJudul] = useState("");
  const [isi, setIsi] = useState("");
  const tutupEdit = () => {
    setId(null);
    setModal(false);
  };
  const bukaEdit = (judul, isi, id) => {
    setJudul(judul);
    setIsi(isi);
    setId(id);
    setModal(true);
  };

  const { userToken } = useAuth();
  const Alert = withReactContent(Swal);

  // VALIDASI
  const validasiEdit = Yup.object().shape({
    isi: Yup.string().required("Link wajib diisi"),
  });

  // GET DATA
  const get = async () => {
    try {
      const response = await axios.get("/api/admin/kontak", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // UPDATE DATA
  const update = async (e) => {
    e.preventDefault();
    const formEdit = new FormData();
    formEdit.append("isi", isi);

    try {
      await validasiEdit.validate({ isi }, { abortEarly: false });
      setIsDisabled(true);
      setErrors({});
      axios
        .post(`/api/admin/kontak/update/${id}`, formEdit, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(() => {
          resetForm();
          Alert.fire({
            title: <p>Update Data Berhasil !</p>,
            icon: "success",
          });
          handleReloadTable();
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsDisabled(false);
      setErrors(validationErrors);
    }
  };

  // RESET FORM
  const resetForm = () => {
    tutupEdit();
    setErrors({});
    setIsDisabled(false);
  };

  const handleReloadTable = () => {
    setReloadTable((prevValue) => !prevValue);
  };

  return (
    <KontakContext.Provider
      value={{
        reloadTable,
        data,
        userToken,
        errors,
        judul,
        isi,
        isDisabled,
        modal,
        handleReloadTable,
        get,
        update,
        setIsi,
        tutupEdit,
        bukaEdit,
      }}
    >
      {children}
    </KontakContext.Provider>
  );
};
