import tentang from "../assets/images/tentang.png";
import { BsWhatsapp } from "react-icons/bs";
import { useBerandaContext } from "../context/BerandaContext";
import React from "react";

function Tentang() {
  const { kontak } = useBerandaContext();
  const handleOpenNewTab = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div className="container mt-section" id="tentang">
      <div className="row">
        <div className="col-lg-5 p-5">
          <img src={tentang} alt="" className="w-100" />
        </div>
        <div className="row col-lg-7 justify-content-center align-items-center">
          <div className="col-9">
            <h2 className="fw-semibold">Tentang Kami</h2>
            <p>
              Kami adalah sebuah tim yang dapat membuat website dengan baik,
              kami memiliki kemampuan yang baik dalam pembuatan website sesuai
              kebutuhan yang anda inginkan, mari bekerja sama dengan kami untuk
              membuat website yang menakjubkan.
            </p>
            <button
              className="btn btn-primary rounded-pill text-white mt-5 d-flex align-items-center"
              onClick={() => handleOpenNewTab(kontak["Whatsapp"])}
            >
              <BsWhatsapp className="me-2" /> Kontak Kami
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Tentang;
