import NavbarAdmin from "../../components/NavbarAdmin";
import Hero from "../../components/admin/Hero";
import React from "react";
import PortofolioCRUD from "../../components/admin/PortofolioCRUD";

const Portofolio = () => {
  return (
    <>
      <Hero judul="Portofolio" />
      <NavbarAdmin />
      <div className="container">
        <PortofolioCRUD />
      </div>
    </>
  );
};
export default Portofolio;
