import React from "react";
import NavbarAdmin from "../../components/NavbarAdmin";
import Hero from "../../components/admin/Hero";
import FaqCRUD from "../../components/admin/FaqCRUD";

const FAQ = () => {
  return (
    <>
      <Hero judul="FAQ" />
      <NavbarAdmin />
      <div className="container">
        <FaqCRUD />
      </div>
    </>
  );
};

export default FAQ;
