import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import React from "react";

const CardDaily = ({ title, icon, link }) => {
  const handleOpenNewTab = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <Card className="text-center py-3">
        <Card.Body>
          <Card.Title>
            <h2>{icon}</h2>
          </Card.Title>
          <Card.Text>
            <h5 className="fw-semibold">{title}</h5>
          </Card.Text>
          <Button variant="primary" onClick={() => handleOpenNewTab(link)}>
            Buka Di Tab Baru
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default CardDaily;
