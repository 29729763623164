import {
  BsEnvelopeAt,
  BsWhatsapp,
  BsInstagram,
  BsYoutube,
  BsTiktok,
} from "react-icons/bs";
import { useBerandaContext } from "../context/BerandaContext";
import React from "react";

function Footer() {
  const { kontak } = useBerandaContext();
  const handleOpenNewTab = (event, link) => {
    event.preventDefault();
    window.open(link, "_blank");
  };
  const styleFooter = {
    marginTop: "300px",
  };
  const scroll = (val) => {
    if (val === 0) {
      window.scrollTo(0, val);
    } else {
      const element = document.getElementById(val);
      if (element) {
        const offset =
          element.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  };
  const handleLinkClick = (event, val) => {
    event.preventDefault();
    scroll(val);
  };
  return (
    <footer className="page-footer bg-primary" id="footer" style={styleFooter}>
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6 py-3">
            <h3>TigeWeb</h3>
            <p>
              Kami adalah sebuah tim yang dapat membuat website dengan baik,
              kami memiliki kemampuan yang baik dalam pembuatan website sesuai
              kebutuhan yang anda inginkan, mari bekerja sama dengan kami untuk
              membuat website yang menakjubkan.
            </p>
            <p className="text-left" id="copyright">
              Copyright &copy; TigeWeb 2023
            </p>
          </div>

          <div className="col-lg-3 py-3">
            <h5>Tautan Cepat</h5>
            <ul className="footer-menu">
              <li>
                <a href="" onClick={(event) => handleLinkClick(event, 0)}>
                  Beranda
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={(event) => handleLinkClick(event, "layanan")}
                >
                  Layanan
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={(event) => handleLinkClick(event, "tentang")}
                >
                  Tentang
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={(event) => handleLinkClick(event, "portofolio")}
                >
                  Portofolio
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={(event) => handleLinkClick(event, "pricelist")}
                >
                  Pricelist
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={(event) => handleLinkClick(event, "footer")}
                >
                  Kontak
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 py-3">
            <h5>Sosial Media</h5>
            <div className="social-media-button">
              <a
                href=""
                onClick={(event) => handleOpenNewTab(event, kontak["Email"])}
                className="me-1"
              >
                <BsEnvelopeAt />
              </a>
              <a
                href=""
                onClick={(event) => handleOpenNewTab(event, kontak["Whatsapp"])}
                className="me-1"
              >
                <BsWhatsapp />
              </a>
              <a
                href=""
                onClick={(event) =>
                  handleOpenNewTab(event, kontak["Instagram"])
                }
                className="me-1"
              >
                <BsInstagram />
              </a>
              <a
                href=""
                onClick={(event) => handleOpenNewTab(event, kontak["Youtube"])}
                className="me-1"
              >
                <BsYoutube />
              </a>
              <a
                href=""
                onClick={(event) => handleOpenNewTab(event, kontak["TikTok"])}
                className="me-1"
              >
                <BsTiktok />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
